<template>
  <div class="SatffManagement">
    <div class="JobPool-content">
      <div class="serach_box">
        <a-form layout="inline" style="width:80%;">
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="persionnal_status"
              placeholder="员工状态"
            >
              <a-select-option
                v-for="(item, k) in persionnal_status_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-tree-select
              style="min-width: 220px;max-width:360px;"
              :treeData="posts_type_arr"
              :value="posts_type"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              treeCheckable
              :showCheckedStrategy="SHOW_PARENT"
              placeholder="请选择岗位"
            />
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="needs_type"
              @change="change"
              placeholder="虚拟人员"
            >
              <a-select-option value="0">不显示</a-select-option>
              <a-select-option value="1">显示</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-range-picker
              style="width:220px;"
              format="YYYY-MM-DD"
              :placeholder="['起始时间', '截止时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="pressing_type"
              placeholder="快速选择"
            >
              <a-select-option
                v-for="(item, k) in pressing_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input
              placeholder="请输入"
              style="width:200px;"
              v-model="classify_name"
            />
          </a-form-item>
          <a-form-item>
            <a-button style="margin-right:20px" @click="resetBtn()"
              >重置</a-button
            >
            <a-button type="primary" @click="serachBtn()">查询</a-button>
          </a-form-item>
        </a-form>
        <router-link
          tag="div"
          class="map-position ant-menu-item-selected"
          to="/jobStatistics"
        >
          <a-icon
            type="bar-chart"
            style="font-size:18px;vertical-align: middle;"
          />
          招聘统计
        </router-link>
      </div>

      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        :customRow="rowClick"
        :scroll="{ x: '150%' }"
        style="margin-top:10px;border:1px solid #efefef"
      >
        <template slot="action" slot-scope="columnsInfo">
          <!-- :trigger="['click']"    -->
          <a-dropdown :trigger="['hover']">
            <a style="display:block;"> <a-icon type="ellipsis"/></a>
            <a-menu slot="overlay">
              <a-menu-item key="0">
                <span @click="drawerRecordsFn('入职转正')">入职转正</span>
              </a-menu-item>
              <a-menu-item key="1">
                <span @click="drawerRecordsFn('岗位异动')">岗位异动</span>
              </a-menu-item>
              <a-menu-item key="2">
                <span @click="drawerRecordsFn('办理离职')">办理离职</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <!-- <a-tooltip placement="left" overlayClassName="tooltipBg">
                        <template slot="title">
                            <div style="padding: 0px 4px;">
                                <p class="hoverColor" @click="stopFn(columnsInfo)">停止招聘{{columnsInfo.key}}</p>
                                <p class="hoverColor" @click="delFn(columnsInfo)" style="margin-top:2px;">删除{{columnsInfo.age}}</p>
                            </div>
                        </template>
                        <a-icon type="ellipsis" style="font-size: 28px;font-weight: 800;" />
                    </a-tooltip> -->
        </template>
        <template slot="footer" slot-scope="currentPageData">
          <div style="font-weight:bold;">
            <span style="margin-right:20px;"> 合计:</span> {{ '36' }}
          </div>
        </template>
      </a-table>
      <div style="margin: 20px 0;float:right">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="total"
          :pageSize="pagesize"
          @change="pageChange"
        />
      </div>
    </div>
    <a-drawer
      :title="controlType"
      width="380"
      placement="right"
      :closable="true"
      @close="onClose"
      :visible="drawerBool"
    >
      <div v-if="controlType == '入职转正'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="转正类型">
            <a-select v-model="tans_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in change_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="指派人">
            <a-input type="text" v-model="CVname" placeholder="请输入姓名" />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="生效日期">
            <a-date-picker @change="onChange" />
            <!-- :disabledDate="disabledDate" -->
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="抄送人">
            <person
              v-bind="CCopyObj"
              style="height: 38px;line-height: 20px;"
              @getData="CCopyFn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="CVeducation" placeholder="请选择">
              <a-select-option
                v-for="item in CVeducation_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <!-- <a-divider orientation="left">发送消息通知</a-divider> -->
          <h3>发送消息通知</h3>
          <div class="ant-form-item_botton">
            <a-form-item v-bind="formItemLayout2" label="员工转正申请表">
            </a-form-item>
            <a-form-item v-bind="formItemLayout2" label="员工转正评估表(上级)">
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              label="员工转正评估表(上上级)"
            >
            </a-form-item>
            <a-form-item v-bind="formItemLayout2" label="员工转正评估表(HR)">
              {{ '__USER__.name' }}
            </a-form-item>
          </div>
        </a-form>
      </div>
      <div v-else-if="controlType == '岗位异动'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="异动类别">
            <a-select v-model="change_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in change_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="原职位">
            员工
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="原部门"
            v-show="change_type == '借调'"
          >
            系统研发部
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            :label="
              change_type == '平调' || change_type == '借调'
                ? '异动后职位'
                : change_type + '后职位'
            "
          >
            <a-tree-select
              style="width:100%;"
              :treeData="posts_type_arr"
              v-model="posts_type_drawer"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              :showCheckedStrategy="SHOW_PARENT"
              searchPlaceholder="请选择岗位"
            />
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="异动后部门"
            v-show="change_type == '借调' || change_type == '平调'"
          >
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="departmentObj"
              @getData="departmentFn"
            ></person>
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="生效日期"
            v-show="change_type != '借调'"
          >
            <a-date-picker
              style="width:100%;"
              v-model="dateTimer"
              @change="onChange"
              placeholder="请选择日期"
            />
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="借调时间"
            v-show="change_type == '借调'"
          >
            <a-range-picker
              style="width:100%;"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="原因">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div v-else-if="controlType == '办理离职'" style="padding-bottom:60px;">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="离职类别">
            <a-select v-model="quit_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in quit_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="离职原因">
            <a-select placeholder="请选择">
              <a-select-option value="合同到期终止">{{
                '合同到期终止'
              }}</a-select-option>
              <a-select-option value="协商解除/劝退">{{
                '协商解除/劝退'
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="人资面谈人">
            {{ __USER__.name }}
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="离职时间">
            <a-date-picker
              style="width:100%;"
              v-model="dateTimer"
              @change="onChange"
              placeholder="请选择日期"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="CVeducation" placeholder="请选择">
              <a-select-option
                v-for="item in CVeducation_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="详细说明">
            <a-textarea placeholder="请输入" :rows="4" />
          </a-form-item>
          <p>指派人</p>
          <a-form-item v-bind="formItemLayout" label="财务">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="contackObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="中心IT">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="contackObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="IT经理">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="contackObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <a-form-item label="抄送人（可多人）">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="CCopyObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <div style="color:#F5222D;">
            <p>规则说明：</p>
            <p>1、在确定员工离职后人事专员发离职工单;</p>
            <p>2、财务部确认借款已归还;</p>
            <p>3、中心IT收回IT设备和禁用域帐号和门禁</p>
            <p>4、总部IT禁用员工邮箱</p>
            <p>
              5、人事部查核清单,单独通知删除员工(孕期教育)、子女(早教课)的学位和排课,如有续课需要续费后按一般客户对待,最后更改km账号状态为离职;
            </p>
          </div>
        </a-form>
      </div>
      <div class="drawer-btns" style="left:0%;padding-left:8%">
        <a-button type="default" @click="drawerBool = false" class="drawer-btn"
          >取消</a-button
        ><a-button class="drawer-btn" type="primary">提 交</a-button>
      </div>
    </a-drawer>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
  </div>
</template>
<script>
const columns = [
  { title: '姓名', width: 180, dataIndex: 'name', key: 'name', fixed: 'left' },
  { title: '手机', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
  { title: '账号', dataIndex: 'address', key: '1' },
  { title: '工号', dataIndex: 'address', key: '2' },
  { title: '入职时间', dataIndex: 'address', key: '3' },
  { title: '工龄', dataIndex: 'address', key: '4' },
  { title: '邮箱', width: 180, dataIndex: 'address', key: '5' },
  { title: '职位', dataIndex: 'address', key: '6' },
  { title: '所属中心', dataIndex: 'address', key: '7' },
  { title: '部门', dataIndex: 'address', key: '8' },
  { title: '状态', dataIndex: 'address', key: '9' },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' }
  }
]

const data = [
  {
    key: '1',
    name: '温雅停',
    age: 32,
    address: 'New York Park',
    id: '910e47faf7f49998e33fcbc06db9712f'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    address: 'London Park',
    id: '5d37238944ba905e9c37a7045e4653f4'
  }
]
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
import {
  __dateTransation, //插件时间格式化公共方法
  __persionnal_Status,
  __quit_type,
  __arr_experience,
  __fast_Select
} from '@/assets/js/public_ren'
export default {
  name: 'SatffManagement',
  data () {
    return {
      SHOW_PARENT,
      treeData: [],
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      spinning: false,
      clientH: '400px',
      //table
      data,
      columns,
      //搜索
      posts_type_arr: [], //岗位
      posts_type: [],
      persionnal_status_arr: __persionnal_Status(), //员工状态
      persionnal_status: undefined,
      pressing_type_arr: __fast_Select(), //快速选择
      pressing_type: undefined,
      needs_type: undefined, //虚拟人员 0不 1为显示
      classify_name: '', //input输入框
      startDate: '',
      endDate: '',
      pagesize: 15, //每页5条
      index: 1, //页码
      total: 1, //总数
      //侧边栏
      quit_type_arr: __quit_type(), //离职类别
      quit_type: undefined,
      drawerBool: false, //侧边栏的显隐
      controlType: '', //侧边栏的标题名
      //异动类别
      change_type: '平调',
      change_type_arr: [
        { name: '平调', value: '平调' },
        { name: '借调', value: '借调' },
        { name: '晋升', value: '晋升' },
        { name: '降级', value: '降级' }
      ],
      posts_type_drawer: [], //异动后职位
      department_value: [], //借调后的部门
      startDate: '',
      endDate: ''
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 248 + 'px'
    if (window.sessionStorage.getItem('slePerson')) {
      this.treeData = JSON.parse(window.sessionStorage.getItem('slePerson'))
    } else {
      this.$axios.get(8002, { Pid: 0 }, res => {
        if (res.data.list) {
          this.treeData = res.data.list
          window.sessionStorage.setItem(
            'slePerson',
            JSON.stringify(res.data.list)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    }

    if (!sessionStorage.getItem('posts_type_arr')) {
      this.$axios.get(8007, {}, res => {
        console.log(res)
        if (res.data.code == 1) {
          this.posts_type_arr = res.data.data
          window.sessionStorage.setItem(
            'posts_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    } else {
      this.posts_type_arr = JSON.parse(
        window.sessionStorage.getItem('posts_type_arr')
      )
    }
    //获取需求类型
    this.$axios.get(8004, {}, res => {
      if (res.data.code == 1) {
        this.needs_type_arr = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })

    //紧急程度
    this.$axios.get(8006, {}, res => {
      if (res.data.code == 1) {
        this.pressing_type_arr = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
    this.tableFn()
  },
  methods: {
    //选择岗位
    onChangeTree (value) {
      console.log(value)
      this.posts_type = value
    },
    change (val) {
      console.log(
        this.posts_type,
        this.pressing_type,
        this.pressing_type,
        this.needs_type,
        this.classify_name,
        this.startDate,
        this.endDate,
        this.index
      )
      console.log(val)
    },
    //搜索时间
    changeTime (value) {
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },
    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn()
    },
    //重置
    resetBtn () {
      console.log(
        this.posts_type,
        this.needs_type,
        this.persionnal_status,
        this.pressing_type
      )
      this.persionnal_status = undefined
      this.needs_type = undefined
      this.posts_type = ''
      this.pressing_type = undefined
      this.classify_name = ''
      this.startDate = ''
      this.endDate = ''
      this.index = 1
      // this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
    },
    //搜索按钮
    serachBtn () {
      console.log(
        this.posts_type,
        this.pressing_type,
        this.pressing_type,
        this.needs_type,
        this.classify_name,
        this.startDate,
        this.endDate
      )
      if (
        !this.posts_type ||
        !this.pressing_type ||
        !this.pressing_type ||
        !this.needs_type ||
        !this.classify_name ||
        !this.startDate ||
        !this.endDate
      ) {
        that.$message.error('都为空不请求!')
        return false
      }
      this.tableFn()
    },
    // 点击table
    rowClick (record, index) {
      return {
        on: {
          click: () => {
            this.$router.push({
              path: '/Staff/Personal',
              query: {
                personId: record.id
              }
            })
            console.log(record, index)
          }
        }
      }
    },
    //停止招聘
    stopFn (val) {
      console.log(val)
    },
    delFn (val) {
      console.log(val)
    },
    //请求ajax
    tableFn (
      index = 1,
      persionnal_status = '',
      needs_type = '',
      posts_type = '',
      pressing_type = '',
      startDate = '',
      endDate = ''
    ) {
      let that = this
      that.spinning = true
      that.$axios.get(
        9971,
        {
          Status: that.persionnal_status, //完成状态
          PositionLabel: that.posts_type, //职位Id
          DemandType: that.needs_type, //需求类型
          StartDate: that.startDate,
          EndDate: that.endDate,
          UrgentLavel: pressing_type, //紧急程度
          DemandUserId: JSON.parse(window.sessionStorage.getItem('userInfo'))
            .uid,
          p: that.index, //页码
          pagesize: 15
        },
        res => {
          that.spinning = false
          console.log(res.data)
          if (res.data.code != 1) {
            that.$message.error(res.data.msg)
            return false
          }
        }
      )
    },
    // 侧边栏
    onClose () {
      //侧边栏隐藏
      this.controlType = ''
      this.drawerBool = false
    },
    departmentFn (val) {
      //借调后的部门
      this.department_value = val
    },
    //抄送人
    CCopyFn (val) {
      this.CCopy_ren = val
    },
    //更多操作
    drawerRecordsFn (type) {
      let that = this
      that.drawerBool = true
      that.controlType = type
    }
  }
}
</script>
<style lang="less" scoped>
.SatffManagement {
  margin-top: 21px;
  .JobPool-content {
    margin-left: 10px;
    width: 100%;
    .serach_box {
      position: relative;
      .map-position {
        position: absolute;
        top: 4px;
        right: 0px;
      }
      .item-label {
        margin-right: 12px;
        margin-bottom: 12px;
        width: 160px;
      }
    }
  }
}
.ant-form-item {
  margin-bottom: 14px;
}
</style>
